body {
  /* background: linear-gradient(180deg, #a7abdd, #29335c 100%); */
  background: #29335c;
  color: white;
}

/* CUSTOM */
.header-arrow {
  margin-bottom: 20px;
  transition: 700ms;
  border-bottom: 3px solid transparent;
  color: rgba(167, 171, 221, 1);
  font-size: 30;
}
.header-arrow:hover {
  text-decoration: none;
  border-color: rgba(33, 37, 41, 1);
  color: rgba(33, 37, 41, 1);
}

.navbar-dark .navbar-nav .nav-link {
  font-family: "Archivo Narrow";
  font-weight: 300;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

/* SPLASH */
.masthead {
  min-height: 30rem;
  display: table;
  width: 100%;
  height: auto;
  padding: 5rem;
  background: linear-gradient(180deg, #a7abdd, #29335c 100%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
span#typed {
  font-family: "Open Sans", sans-serif;
  font-size: 50px;
  margin: 0;
  font-weight: 800;
  padding: 0;
  color: white;
  text-align: right;
  padding-bottom: 30px;
  max-width: 700px;
}

.masthead h3 {
  font-family: "Archivo Narrow";
  margin-bottom: 0px !important;
  font-weight: 300;
  letter-spacing: 1.5px;
}

.left-border {
  border-left: 2px solid white;
}

.text-col {
  padding-left: 20px;
  flex-direction: column;
  max-width: 300px;
  min-width: 225px;
}

.title-col {
  margin-right: 10px !important;
}

@media (min-width: 992px) {
  .masthead {
    height: 100vh;
  }
  span#typed {
    font-size: 4.5rem;
  }
}

@media (max-width: 770px) {
  .masthead {
    padding: 2rem;
  }
  .text-col {
    width: 100%;
    justify-content: center !important;
    margin: 0px auto;
    padding-top: 15px;
    min-width: 300px;
  }

  .title-wrapper {
    flex: 1;
  }

  .title-col {
    justify-content: center !important;
    margin: 0px auto;
    display: flex;
    flex: 1;
  }
}

@media (max-width: 670px) {
  .left-border {
    border-left: none;
  }

  .text-col {
    border-top: 2px solid white;
  }
}

/* SERVICES */
.content-section {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.content-section-heading h2 {
  font-size: 1rem;
  text-transform: lowercase;
  font-size: 3rem;
}
.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Archivo Narrow";
  font-weight: 300;
  letter-spacing: 1.5px;
}
.service-icon {
  background-color: #a7abdd;
  color: white;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.about-icon {
  background-color: #fff;
  color: white;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.team-icon {
  background-color: #fff;
  color: white;
  height: 12rem;
  width: 12rem;
  display: block;
  line-height: 12rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

/* ABOUT US */
.content-section-gradient {
  background: linear-gradient(180deg, #a7abdd, #29335c 100%);
}

/* PORTFOLIO */
.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;
}
.portfolio-item img {
  min-height: 350px;
}
.portfolio-item .caption {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.portfolio-item .caption .caption-content {
  color: #fff;
  margin: auto 2rem 2rem;
}
.portfolio-item .caption .caption-content h2 {
  font-size: 0.8rem;
  text-transform: uppercase;
}
.portfolio-item .caption .caption-content p {
  font-weight: 300;
  font-size: 1.2rem;
}
@media (min-width: 992px) {
  .portfolio-item {
    max-width: none;
    margin: 0;
  }
  .portfolio-item .caption {
    background-color: rgba(41, 51, 92, 0.5);

    -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s;
    -webkit-clip-path: inset(0px);
    clip-path: inset(0px);
  }
  .portfolio-item .caption .caption-content {
    transition: opacity 0.25s;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 5rem;
  }
  .portfolio-item img {
    -webkit-transition: -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(-1px);
    clip-path: inset(-1px);
  }
  .portfolio-item:hover img {
    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem);
  }
  .portfolio-item:hover .caption {
    background-color: rgba(41, 51, 92, 1);

    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem);
  }
}

@media (max-width: 450px) {
  .portfolio-item img {
    min-height: 0px !important;
  }
}

.modal-dialog {
  max-width: 800px;
  -webkit-box-shadow: 0px 6px 49px 34px rgba(167, 171, 221, 0.4);
  -moz-box-shadow: 0px 6px 49px 34px rgba(167, 171, 221, 0.4);
  box-shadow: 0px 6px 49px 34px rgba(167, 171, 221, 0.4);
}

.modal-open .modal {
  background-color: rgba(41, 51, 92, 0.5);
}

.modal.show .modal-dialog {
  border-radius: 12px;
}

.modal-content {
  border: 0px;
  border-radius: 8px;
  background-color: #29335c;
  color: white;
}

button.close {
  color: white;
}

.modal-header {
  border-bottom-width: 0px;
  padding-bottom: 0px;
}

/* FOOTER */
footer.footer {
  min-height: 50vh;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -2rem;
  background-color: #29335c !important;
}
footer.footer .social-link {
  display: block;
  height: 4rem;
  width: 4rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color: #a7abdd;
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
footer.footer .social-link:hover {
  background-color: #a7abdd;
  text-decoration: none;
}

.text-muted {
  color: white !important;
  opacity: 0.7;
}

/* SCROLL TO TOP BUTTON */
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  border-radius: 25px !important;
  text-align: center;
  color: #29335c;
  background: white;
  line-height: 45px;
}

.scroll-to-top:hover {
  background: #a7abdd;
}
.scroll-to-top i {
  font-weight: 800;
}

/* Side Menu */
#sidebar-wrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background: #29335c;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-nav li.sidebar-nav-item a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 15px;
}
.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}
.sidebar-nav > .sidebar-brand {
  font-size: 1.2rem;
  background: rgba(52, 58, 64, 0.1);
  height: 80px;
  line-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.sidebar-nav > .sidebar-brand a {
  color: #fff;
}
.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}
#sidebar-wrapper.active {
  right: 250px;
  width: 250px;
  transition: all 0.4s ease 0s;
}
.menu-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  background: rgba(52, 58, 64, 0.5);
  line-height: 50px;
  z-index: 999;
}
.menu-toggle:focus,
.menu-toggle:hover {
  color: #fff;
}
.menu-toggle:hover {
  background: #343a40;
}

.text-secondary {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Archivo Narrow";
  font-weight: 300;
  letter-spacing: 1.5px;
  color: rgba(256, 256, 256, 0.7) !important;
}
