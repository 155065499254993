body,
html {
  width: 100%;
  height: 100%;
}
body {
  font-family: "Open Sans";
  color: "white";
  background-color: "black";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
h4 {
  font-size: 1rem;
  font-weight: 300;
  font-size: 2rem;
}
.bg-primary {
  background-color: #29335c !important;
}
.bg-secondary {
  background: linear-gradient(180deg, #29335c, #a7abdd 100%);
}
